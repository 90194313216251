.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Spacing adjustments for footer */
.logo-section {
  margin-bottom: 1rem; 
}

.footer-columns {
  gap: 2.5rem; 
}

.footer-title {
  font-size: 1.25rem; 
  font-weight: 600;
  color: #1f2937;
}

#dropdownMenu {
  z-index: 50;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out;
}

/* Dropdown menu */
#dropdownMenu {
  background-color: white;
  border-radius: 0.5rem; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s ease, opacity 0.2s ease; 
}


#dropdownMenu a:hover {
  background-color: #f3f4f6;
  color: #2563eb; 
}

/* For mobile dropdowns */
#dropdownMenu.mobile {
  width: 100%;
  max-width: none;
  border: none;
  border-top: 1px solid #e5e7eb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out;
}

.logo-section {
  margin-bottom: 1rem; 
}

.footer-columns {
  gap: 2.5rem; 
}

.footer-title {
  font-size: 1.25rem; 
  font-weight: 600;
  color: #1f2937; 
}