.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #ffffff; 
    width: 90%;
    max-width: 400px;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .modal-field {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .modal-label {
    display: block;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .modal-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 1rem;
  }
  
  .forgot-password-link {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #2563eb; 
    text-decoration: none;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .modal-btn {
    flex: 1;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin: 0 0.5rem;
    transition: background-color 0.3s;
  }
  
  .modal-cancel-btn {
    background-color: #f3f4f6; 
    color: #374151;
  }
  
  .modal-cancel-btn:hover {
    background-color: #e5e7eb; 
  }
  
  .modal-login-btn {
    background-color: #8ADBFF; 
    color: white;
  }
  
  .modal-login-btn:hover {
    background-color: #0192CF; 
  }
  
  .login-btn {
    background-color: #2563eb; 
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }
  
  .login-btn:hover {
    background-color: #0192CF; 
  }

  .error-message {
    color: #e53e3e;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out;
}