.recruiter-form-container {
    max-width: 40rem;
    margin: 2.5rem auto;
    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  .recruiter-form-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .recruiter-form-field {
    margin-bottom: 1rem;
  }
  
  .recruiter-form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  .recruiter-form-input,
  .recruiter-form-textarea {
    margin-top: 0.25rem;
    padding: 0.5rem;
    display: block;
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  .recruiter-form-input:focus,
  .recruiter-form-textarea:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
  }
  
  .recruiter-form-error {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .recruiter-form-submit-btn {
    margin-top: 1.5rem;
    padding: 0.75rem;
    width: 100%;
    background-color: #0192CF;  /* Light Blue */
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .recruiter-form-submit-btn:hover {
    background-color: #8ADBFF;
  }
  
  